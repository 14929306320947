import React from "react";
import Container from "@mui/material/Container";

function Clients() {
    return (
        <div className="clients">
            <Container>
                <div className="clients__textarea">
                    <h1 className="clients__title">Nasi klienci</h1>
                    <p className="clients__paragraph"> Obsługiwane przez nas budynku biurowe na terenie Warszawy.</p>
                    <div className="clients__map">
                    <iframe src="https://my.atlistmaps.com/map/e8e96f18-3c65-40d9-8540-f81b271dcab9?share=true" allow="geolocation" width="100%" height="400px" frameborder="0" scrolling="no" allowfullscreen></iframe>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Clients;
