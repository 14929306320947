import "./App.scss";
import Home from "./components/Home";

function App() {
    return (
        <>
            <Home/>
        </>
    );
}

export default App;
